import http from "./http-common";

////////// Start : Visitor User //////////

// Api : register visitor user 
const registerVisitor = (eventId: string, firstName: string, lastName: string, phone: number, email: string) => {
    return http.post(`v1/PhotoFaceFilter/register`, {
        "eventId": eventId,
        "firstName": firstName,
        "lastName": lastName,
        "phone": phone,
        "email": email,
    });
}

////////// End : Visitor User //////////

////////// Start : Image //////////

// Api : Retrive Face Images
const retriveFaceImages = (body: any) => {
    return http.post(`v1/PhotoFaceFilter/RetriveFaceImages`, body)
}

// Api : Get Event Basic Details By Id
const getEventById = (eventId: any) => {
    return http.get(`v1/PhotoFaceFilter/GetEventBasicDetailsById/${eventId}`)
}

// Api : verify Event Pin
const verifyEventPin = (eventId: any, pin: number) => {
    return http.get(`v1/PhotoFaceFilter/VerifyEventPin/${eventId}/${pin}`)
}

// Api : get Dynamic Image List
const getDynamicImageList = (params: any) => {
    return http.get(`v1/PhotoFaceFilter/GetDynamicImageDataList`, params)
}

// Api : validate Face
const verifyFace = (body: any) => {
    return http.post(`v1/PhotoFaceFilter/ValidateFace`, body)
}

// Api : get Image Data By Id
const getImageDataById = (id: string) => {
    return http.get(`v1/PhotoFaceFilter/GetImageDataById/${id}`)
}

////////// End : Image //////////


const ApiService = {
    registerVisitor,
    retriveFaceImages,
    getEventById,
    verifyEventPin,
    getDynamicImageList,
    verifyFace,
    getImageDataById
}

export default ApiService