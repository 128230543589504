import { useState, useEffect } from "react";

// navigation
import { useNavigate } from "react-router-dom";

// filesaver-download image
import { saveAs } from "file-saver";

// Sidebar Component
import Sidebar from "../menu/Sidebar";

// material-ui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

// Moment
import moment from "moment";

// AppContext
import { useAppContext } from "../hooks/useAppContext";

//Lazyload image component
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// logos and Images
import addImg from "../assets/icons/addImg.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  "@media (min-width: 768px)": {
    width: "600px",
  },
};

// Api url
const API_URL = process.env.REACT_APP_API_KEY;

let eventId: any = "";

const Home = ({ scrollPosition }: any) => {
  const navigate = useNavigate();

  const {
    imageList,
    retriveFaceDetails,
    setIsCaptureImage,
    setIsRegister,
    setIsFullEventAccess,
    setIsVerifyPin,
  } = useAppContext();

  const [show, setShow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [coverImage, setCoverImage] = useState("");

  useEffect(() => {
    if (sessionStorage.getItem("eventId_fulleventaccess")) {
      eventId = sessionStorage.getItem("eventId_fulleventaccess");
      const coverImageUrl: any = localStorage.getItem(
        `coverImage_fulleventaccess_${eventId}`
      );
      setCoverImage(coverImageUrl);
    } else {
      eventId = sessionStorage.getItem("eventId");
      const coverImageUrl: any = localStorage.getItem(`coverImage_${eventId}`);
      setCoverImage(coverImageUrl);
    }
    // if (sessionStorage.getItem("eventId_fulleventaccess")) {
    //   const coverImageUrl: any = localStorage.getItem(
    //     `coverImage_fulleventaccess_${eventId}`
    //   );
    //   setCoverImage(coverImageUrl);
    // } else {
    //   const coverImageUrl: any = localStorage.getItem(`coverImage_${eventId}`);
    //   setCoverImage(coverImageUrl);
    // }
  }, []);

  // Function for Logout Action
  const logOut = () => {
    localStorage.removeItem(`token_${eventId}`);
    localStorage.removeItem(`imageList_${eventId}`);
    setIsCaptureImage(false);
    setIsRegister(false);
    setIsFullEventAccess(false);
    setIsVerifyPin(false);
    localStorage.removeItem(`coverImage_${eventId}`);
    localStorage.removeItem(`isVerifyPin_${eventId}`);
    localStorage.removeItem(`isFullEventAccess_${eventId}`);
    localStorage.removeItem(`isRegister_${eventId}`);
    localStorage.removeItem(`isCaptureImage_${eventId}`);
    navigate(`/${eventId}`);
  };

  return (
    <>
      <div>
        <div className="relative">
          <div
            className="overflow-hidden relative lg:h-[90vh] bg-center bg-no-repeat bg-cover aspect-video p-[3rem] w-full max-sm:px-4 max-lg:p-6"
            style={{ backgroundImage: `url('${API_URL}/${coverImage}')` }}
          >
            <div>
              <Sidebar isShow={true} />
            </div>
            <div className="flex justify-center items-start flex-col h-full">
              <h2 className="text-white text-3xl font-semibold uppercase">
                {retriveFaceDetails && retriveFaceDetails.eventName}
              </h2>
              <p className="text-white text-lg font-semibold uppercase">
                {retriveFaceDetails &&
                  moment(retriveFaceDetails.eventDate).format("Do MMM YYYY")}
              </p>
            </div>
          </div>

          <div className="px-[3rem] pt-10 pb-3 max-sm:px-4 max-lg:p-6">
            <div className="flex justify-between items-center max-sm:flex-wrap">
              <div>
                <h2 className="text-black text-3xl font-semibold uppercase max-sm:text-lg">
                  {retriveFaceDetails && retriveFaceDetails.eventName}
                </h2>
              </div>
              <div className="flex items-center gap-8 max-sm:mt-3">
                <div
                  className="relative flex items-center cursor-pointer"
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  <div
                    style={{ display: show ? "block" : "none" }}
                    className="absolute bg-[#fff] py-2 w-[250px] right-0 top-[30px] shadow-[0_4px_12px_0_rgba(0,0,0,0.2)] rounded z-10 max-sm:left-0"
                  >
                    <div className="border-b-2">
                      <div className="flex relative px-4 py-2 hover:bg-[#4854e240] text-center">
                        <span className="text-sm text-black mt-1 text-center">
                          My Favoutite (0 photos)
                        </span>
                      </div>
                    </div>
                    <div
                      className="font-normal text-sm underline-offset-4 px-4 py-2 hover:bg-[#4854e240] text-center"
                      onClick={() => navigate("/viewlist")}
                    >
                      View All Lists
                    </div>
                    <div
                      className="font-normal text-sm underline-offset-4 px-4 py-2 hover:bg-[#4854e240] text-center"
                      onClick={() => setModalOpen(true)}
                    >
                      Create New
                    </div>
                  </div>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => navigate("/captureImage")}
                >
                  <i className="fas fa-camera text-lg text-[#777]" />
                </div>
                <div onClick={() => logOut()} className="cursor-pointer">
                  <i className="fas fa-sign-out-alt text-lg text-[#777]" />
                </div>
              </div>
            </div>

            <div className="mt-[3rem]">
              <p className="text-base font-normal text-black uppercase mb-10">
                My photo
              </p>
              <div className="grid gap-6 grid-cols-3 max-sm:grid-cols-1">
                {imageList &&
                  imageList.map((row: any, index: number) => {
                    return (
                      <div
                        className="relative photo-div cursor-pointer"
                        key={index}
                        onClick={() => saveAs(`${API_URL}/${row}`, "obama")}
                      >
                        <LazyLoadImage
                          src={`${API_URL}/${row}`}
                          alt="Home Image"
                          className=" img-lazy rounded-md object-cover h-full w-full"
                          width={"100%"}
                          height={"100%"}
                          scrollPosition={scrollPosition}
                          effect="blur"
                        />
                        <div className=" w-full opacity-0 icon-blog">
                          <div className="flex items-center justify-end gap-6 absolute bottom-0 right-0 pr-3 pb-3 photo-icon w-full">
                            {/* <div className="cursor-pointer">
                            <i className="fas fa-share-alt text-white text-lg"></i>
                          </div>
                          <div
                            onClick={() => saveAs(`${API_URL}/${row}`, 'obama')}
                            className="cursor-pointer"
                          >
                            <i className="fas fa-download text-white text-lg"></i>
                          </div>
                          <div className="cursor-pointer">
                            <i className="fas fa-heart text-white text-lg"></i>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div>
                <div className="flex items-start justify-between">
                  <div className="flex flex-col">
                    <h2 className="text-3xl text-black font-semibold leading-[28px]">
                      New Folder
                    </h2>
                    <p className="text-base text-black mt-1 font-normal">
                      Create new folder in your favourites
                    </p>
                  </div>

                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    <i className="fas fa-times " />
                  </div>
                </div>

                <div className="mt-6">
                  <div className="relative">
                    <label className="text-xs font-medium text-[#777]">
                      Enter folder name <span className="text-[#777]">*</span>
                    </label>
                    <input
                      type="text"
                      className="w-full py-2 px-3 border border-[#ddd] outline outline-transparent focus:outline-transparent rounded text-base text-black font-normal placeholder-[#999]"
                    />
                  </div>

                  <div className="mt-6 mx-auto text-center">
                    <button
                      type="submit"
                      className="text-base font-semibold find-btn text-white capitalize tracking-wide py-2 px-4 hover w-[156px]"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>

          {/* material ui add image modal */}
          <Modal
            open={modalAdd}
            onClose={() => setModalAdd(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div>
                <div className="flex items-start justify-between">
                  <div className="flex flex-col">
                    <h2 className="text-3xl text-black font-semibold leading-[28px] uppercase">
                      ADD IMAGES
                    </h2>
                  </div>

                  <div
                    className="cursor-pointer leading-none"
                    onClick={() => {
                      setModalAdd(false);
                    }}
                  >
                    <i className="fas fa-times "></i>
                  </div>
                </div>

                <div className="mt-6">
                  <div className="relative">
                    <div className="text-sm font-medium text-black text-center">
                      Images you upload will be approved by photographer or host
                      before they are available to everyone.
                    </div>
                    <div className="mt-6">
                      <label className="h-44 w-full border-dashed border-2 input-box-shadow border-[#ddd] flex flex-col items-center cursor-pointer p-4 justify-center">
                        <img
                          src={addImg}
                          alt="uploadImg"
                          className="h-14 w-14 mx-auto text-center"
                        />
                        <input
                          type="file"
                          id="myfile"
                          name="myfile"
                          className="opacity-0 text-center h-0"
                        />
                        <button
                          type="submit"
                          className="text-base font-semibold find-btn text-white capitalize tracking-wide py-2 px-4 hover w-[156px] mt-4"
                        >
                          Save
                        </button>
                        <p className="text-center text-sm text-[#adadad] mt-3 font-normal">
                          Maximum upload file size: 30 MB
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default trackWindowScroll(Home);
