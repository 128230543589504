import { useEffect, useState } from "react";

// navigation
import { useNavigate } from "react-router-dom"

// material-ui
import CircularProgress from "@mui/joy/CircularProgress";

// Formik
import { Formik } from 'formik';
import * as Yup from 'yup';

// ApiService 
import ApiService from "../service/ApiDataRequest";

// Sidebar Component
import Sidebar from "../menu/Sidebar";

// appcontext
import { useAppContext } from "../hooks/useAppContext";

// logos and Images
import flag from "../assets/image/india-flag.png"

// RegularExpression for Email validation
const emailRegExp = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

// validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter valid email')
    .matches(emailRegExp, 'Please enter valid email')
    .required('Email is required'),
  firstName: Yup.string()
    .required('FirstName is required'),
  lastName: Yup.string()
    .required('LastName is required'),
  phone: Yup.string()
    .min(10, 'Invalid Phone Number')
    .matches(/^[0-9]{0,10}$/, 'Invalid Phone Number')
});

// Api Url
const API_URL = process.env.REACT_APP_API_KEY
let eventId: any = ''

const Register = () => {

  const { setIsRegister } = useAppContext()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [coverImage, setCoverImage] = useState('')

  useEffect(() => {
    if (sessionStorage.getItem('eventId_fulleventaccess')) {
      eventId = sessionStorage.getItem('eventId_fulleventaccess')
      const coverImageUrl: any = localStorage.getItem(`coverImage_fulleventaccess_${eventId}`)
      setCoverImage(coverImageUrl)
    } else {
      eventId = sessionStorage.getItem('eventId')
      const coverImageUrl: any = localStorage.getItem(`coverImage_${eventId}`)
    setCoverImage(coverImageUrl)
    }
    // if (sessionStorage.getItem('eventId_fulleventaccess')) {
    //   const coverImageUrl: any = localStorage.getItem(`coverImage_fulleventaccess_${eventId}`)
    //   setCoverImage(coverImageUrl)
    // }
    // else{
    // const coverImageUrl: any = localStorage.getItem(`coverImage_${eventId}`)
    // setCoverImage(coverImageUrl)
    // }
  }, [])


  // function for API call to create a new user
  const handleRegistration = async (values: any) => {
    setIsLoading(true)
    const { firstName, lastName, phone, email } = values

    try {
      const response = await ApiService.registerVisitor(eventId, firstName, lastName, phone, email);
      setIsLoading(false)
      localStorage.setItem(`isRegister_${eventId}`, 'true')
      setIsRegister(true)
      navigate("/captureImage")
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className="relative">
        <div className="grid min-h-screen grid-cols-coverImg bg-theme-light dark:bg-dark-gray relative max-md:grid-cols-1">
          <div
            className="pinbg-image overflow-hidden relative lg:h-screen bg-center bg-no-repeat bg-cover aspect-video p-[3rem] w-full max-md:p-6"
            style={{ backgroundImage: `url('${API_URL}/${coverImage}')` }}
          >
            <div>
              <Sidebar
                isShow={false}
              />
            </div>
            <div className="flex justify-center items-start flex-col h-full">
              <h2 className="text-white text-3xl font-semibold uppercase">
                Event
              </h2>
              <p className="text-white text-lg font-semibold uppercase">
                25 Apr 2023
              </p>
            </div>
          </div>
          <div
            className="xl:pl-24 xl:pt-[100px] xl:pr-16 pt-[26px] pb-[36px] max-xl:p-6 max-sm:px-4 relative overflow-hidden  ">
            <div>
              <div className="border-b border-[#f3f3f3] pb-8">
                <h6 className="text-base text-black font-normal leading-[18px]">
                  Get your images using face recognition at
                </h6>
                <h2 className="text-black text-3xl font-bold capitalize mt-2">
                  Event
                </h2>
              </div>
              <div className="pt-8">
                <p className="text-xs text-[#666] font-normal leading-[20px]">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
                <h6 className="text-base text-black font-normal leading-[18px] pt-8 capitalize">
                  Provide a few of your details
                </h6>
                <div>
                  <Formik
                    initialValues={{
                      email: '',
                      firstName: '',
                      lastName: '',
                      phone: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                      handleRegistration(values);
                    }}>
                    {({
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      values,
                      errors,
                      touched,
                    }) => {
                      return (
                        <>
                          <form onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                          }}>
                            <div className="grid grid-cols-2 gap-6 max-sm:grid-cols-1">
                              <div className="my-3">
                                <div className="py-1">
                                  <label
                                    htmlFor="fname"
                                    className="text-base text-[#666]"
                                  >
                                    First Name*
                                  </label>
                                </div>
                                <div className="input-box-shadow rounded-md">
                                  <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    onChange={handleChange('firstName')}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                    className="w-full h-10 rounded-lg focus-visible:outline-none px-3"
                                  />
                                </div>
                                {errors.firstName && touched.firstName ? (
                                  <div className='text-[red]'>{errors.firstName}</div>
                                ) : null}
                              </div>
                              <div className="my-3">
                                <div className="py-1">
                                  <label
                                    htmlFor="lname"
                                    className="text-base text-[#666]"
                                  >
                                    Last Name*
                                  </label>
                                </div>
                                <div className="input-box-shadow rounded-md">
                                  <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    onChange={handleChange('lastName')}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                    className="w-full h-10 rounded-lg focus-visible:outline-none px-3"
                                  />
                                </div>
                                {errors.lastName && touched.lastName ? (
                                  <div className='text-[red]'>{errors.lastName}</div>
                                ) : null}
                              </div>
                            </div>
                            <div className="grid grid-cols-1">
                              <div className="my-3">
                                <div className="py-1">
                                  <label htmlFor="id" className="text-base text-[#666]">
                                    Email*
                                  </label>
                                </div>
                                <div className="input-box-shadow rounded-md">
                                  <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    onChange={handleChange('email')}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    className="w-full h-10 rounded-lg focus-visible:outline-none px-3"
                                  />
                                </div>
                                {errors.email && touched.email ? (
                                  <div className='text-[red]'>{errors.email}</div>
                                ) : null}
                              </div>
                            </div>

                            <div className="grid grid-cols-1">
                              <div className="my-3">
                                <div className="py-1">
                                  <label
                                    htmlFor="phone"
                                    className="text-base text-[#666]"
                                  >
                                    Phone
                                  </label>
                                </div>
                                <div className="input-box-shadow rounded-md relative">
                                  <img src={flag} alt="flag" className="absolute left-[5px] w-5 h-5 top-[10px]" />
                                  <i className="fas fa-sort-down absolute left-[28px] top-[7px] text-[#666] text-sm"></i>
                                  <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    onChange={handleChange('phone')}
                                    onBlur={handleBlur}
                                    value={values.phone}
                                    className="w-full h-10 rounded-lg focus-visible:outline-none pr-3 pl-12"
                                  />
                                </div>
                                {errors.phone && touched.phone ? (
                                  <div className='text-[red]'>{errors.phone}</div>
                                ) : null}
                              </div>
                            </div>

                            <div className="mx-auto text-center mt-10 relative">
                              <button
                                type="submit"
                                className="text-base font-semibold find-btn text-white capitalize tracking-wide py-2 px-4 hover relative w-[13.5rem]"
                              >
                                Find My Image
                              </button>
                              {isLoading && <CircularProgress thickness={2} size="sm" style={{ top: "7px", right: "40px" }} />}
                            </div>
                          </form>
                        </>
                      );
                    }}
                  </Formik>
                </div >
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
