// style
import './App.css';

// Routing Outlet
import { Outlet } from "react-router-dom"


function App() {
  return (
    <Outlet />
  )
}

export default App
