/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState, useCallback } from "react";

// navigation
import { useNavigate } from "react-router-dom";

// material-ui
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
// import CircularProgress from '@mui/material/CircularProgress';

// Appcontext
import { useAppContext } from "../hooks/useAppContext";

// Sidebar Component
import Sidebar from "../menu/Sidebar";

// Api Service
import ApiService from "../service/ApiDataRequest";

// logos and Images
import exclamation from "../assets/image/exclamation1.png";
import uploadImg from "../assets/image/uploadImg.png";

//React-webcam
import Webcam from "react-webcam";

const CaptureImage = () => {
  const navigate = useNavigate();

  const {
    setImageList,
    setRetriveFaceDetails,
    setIsOpen,
    setAlertType,
    setResponseMessage,
    setIsCaptureImage,
  } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isValidImage, setIsValidImage] = useState(false);
  const [uploadImage, setUploadImage] = useState<any>();
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [demoState, setDemoState] = useState(false);
  const [file, setFile] = useState<any>();
  const [image, setImage] = useState<any>();
  const [isHasWebcam, setIsHasWebcam] = useState<any>();
  const imageRef: any = useRef();
  const [picture, setPicture] = useState("");

  const webcamRef: any = useRef();
  let eventId: any = "";

  const capture = useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
  }, [picture]);

  //Function to detect whether user's system has webcam or not
  const detectWebcam = (callback: any) => {
    let md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) return callback(false);
    md.enumerateDevices().then((devices) => {
      callback(devices.some((device) => "videoinput" === device.kind));
    });
  };

  // useEffect for function call to detect webcam
  useEffect(() => {
    detectWebcam(function (hasWebcam: any) {
      hasWebcam ? setIsHasWebcam(true) : setIsHasWebcam(false);
    });
  }, []);

  // function for api call to validate face
  const validateface = async () => {
    const formData = new FormData();

    // const filePath: any = URL.createObjectURL(file);
    for (const img of uploadImage) {
      formData.append("faceImage", img);
    }

    try {
      await ApiService.verifyFace(formData).then((res) => {
        if (res.data.success) {
          setIsValidImage(true);
        } else {
          setIsOpen(true);
          setAlertType("error");
          setResponseMessage(res.message);
        }
      });
    } catch (error) {
      setIsOpen(true);
      setAlertType("error");
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (!!file) {
      validateface();
    }
  }, [file]);

  // Function for Api call to upload event image
  const handleRetriveFaceImages = async (fileData: any) => {
    if (!fileData) {
      return;
    }
    setIsLoading(true);

    const formData: any = new FormData();
    if (sessionStorage.getItem("eventId_fulleventaccess")) {
      eventId = sessionStorage.getItem("eventId_fulleventaccess");
    } else {
      eventId = sessionStorage.getItem("eventId");
    }
    Object.entries({
      eventId: eventId,
    }).forEach(([key, value]) => {
      if (value) formData.append(key, value as string);
    });

    if (!demoState) {
      for (const img of fileData) {
        formData.append("faceImage", img);
      }
    } else {
      var file1 = dataURLtoFile(picture, "demofile.jpeg");
      formData.append("faceImage", file1);
    }

    try {
      const response = await ApiService.retriveFaceImages(formData);
      setIsLoading(false);
      if (response.statusCode === 200) {
        setRetriveFaceDetails(response.data);
        setImageList(response.data.data);
        const imageData = JSON.stringify(response.data.data);
        localStorage.setItem(`imageList_${eventId}`, imageData);
        localStorage.setItem(`isCaptureImage_${eventId}`, "true");
        setIsCaptureImage(true);
        navigate("/home");
        setIsOpen(true);
        setAlertType("success");
        setResponseMessage(response.message);
        setDemoState(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      console.log("error", error);
      setIsOpen(true);
      setAlertType("error");
      setResponseMessage(error.data[0].description);
      setDemoState(false);
    }
  };

  // function to convert base64 image url into image file
  const dataURLtoFile = (dataurl: any, filename: string) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = window.atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  // useEffect to call handleRetriveFaceImages function to get match face images
  useEffect(() => {
    if (isValidImage) {
      handleRetriveFaceImages(uploadImage);
    }
  }, [isValidImage]);

  useEffect(() => {
    if (picture !== "") {
      handleRetriveFaceImages(picture);
    }
  }, [picture]);

  return (
    <>
      <div>
        <div className="relative p-[3rem] max-md:p-4">
          <Sidebar isShow={true} />
          <div
            className="flex items-center justify-start cursor-pointer my-10"
            onClick={() => navigate("/")}
          ></div>
          <div className="box-inner-shadow flex items-center gap-6 max-md:flex-wrap py-16 max-sm:px-4 max-lg:px-[2rem]">
            <div className="basis-1/5 max-xl:basis-0"></div>

            {!isHasWebcam && !isCameraOpen && (
              <div className="text-center basis-[30%] max-xl:basis-1/2 max-md:basis-full">
                <div className="mx-auto text-center">
                  <img
                    src={exclamation}
                    alt="exclamation"
                    className="h-14 w-14 mx-auto text-center"
                  />
                </div>
                <h6 className="text-lg font-semibold text-black capitalize mt-6">
                  We can’t seem to access your camera, try again or upload a
                  picture.
                </h6>
              </div>
            )}

            {isCameraOpen && (
              <>
                <Webcam
                  audio={false}
                  height={400}
                  ref={webcamRef}
                  width={400}
                  screenshotFormat="image/jpeg"
                />
                <button
                  className="find-btn text-white text-base font-semibold capitalize tracking-wide py-2 px-4 input-box-shadow mt-6"
                  onClick={() => {
                    setDemoState(true);
                    return (
                      capture(),
                      setIsCameraOpen(false)
                    );
                  }}
                >
                  Capture Photo
                </button>
              </>
            )}

            {!isCameraOpen && (
              <div className="text-center basis-[30%] max-xl:basis-1/2 max-md:basis-full max-md:mt-8">
                <label
                  className={`h-72 w-72 border-dashed border-2 border-[#ddd] rounded-full mx-auto flex flex-col items-center justify-center max-md:h-48 max-md:w-48 ${
                    !isLoading ? "cursor-pointer " : ""
                  }`}
                >
                  <img
                    src={uploadImg}
                    alt="uploadImg"
                    className="h-14 w-14 mx-auto text-center"
                  />
                  <a className="text-lg font-semibold text-black underline mt-4">
                    Browse
                    <input
                      type="file"
                      disabled={isLoading}
                      onChange={(e: any) => {
                        setFile(e.target.files[0]);
                        setUploadImage(e.target.files);
                      }}
                      id="myfile"
                      name="myfile"
                      className="opacity-0 h-0 w-0 leading-none"
                    />
                  </a>
                </label>

                {isHasWebcam && (
                  <button
                    className="find-btn text-white text-base font-semibold capitalize tracking-wide py-2 px-4 input-box-shadow mt-6"
                    disabled={isLoading}
                    onClick={() => {
                      setDemoState(true);
                      return setIsCameraOpen(true);
                    }}
                  >
                    Take Photo
                  </button>
                )}
              </div>
            )}
            <img
              hidden
              ref={imageRef}
              src={file && image && image.url}
              alt={file?.name ? file.name : ""}
            />
            <div className="basis-1/5 max-xl:basis-0"></div>
          </div>
          {isLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </div>
      </div>
    </>
  );
};

export default CaptureImage;
