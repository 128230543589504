import unpublished from "../assets/image/unpublished.png";

const UnPublishPage = () => {
  return (
    <div
      className=" flex items-center justify-center"
      style={{ height: "100vh" }}
    >
      <div className="text-center flex items-center justify-center flex-col">
        <div>
          <img
            src={unpublished}
            alt="Unpublished"
            className="w-[5.5rem] mx-auto"
          />
        </div>
        <h2 className="text-center mt-2 text-2xl text-black font-medium">
          This event has been unPublished
        </h2>
        <h4 className="text-center mt-2 text-sm text-[#737373] font-normal capitalize">
          Please contact your photographer for more details.
        </h4>
      </div>
    </div>
  );
};

export default UnPublishPage;
