/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from 'react';

// material-ui
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';

// logos and Images
import menu from "../assets/icons/menu.png"
import instagram from "../assets/image/instagram.png"
import facebook from "../assets/image/facebook.png"
import youtube from "../assets/image/youtube.png"


const Sidebar = ({ isShow }: any) => {

  type Anchor = 'top' | 'left' | 'bottom' | 'right';
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };


  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="relative p-10 pb-16">
        <div className="mb-6">
          <i className="fas fa-times cursor-pointer text-2xl "></i>
        </div>
        <ul>
          <li className="mb-8">
            <a href="#" className="text-2xl font-normal text-black capitalize">Call</a>
          </li>
          <li className="mb-8">
            <a href="#" className="text-2xl font-normal text-black capitalize">email</a>
          </li>
        </ul>
      </div>
      <Divider />

      <div className="relative p-10">
        <ul className="flex items-center justify-center gap-6">
          <li>
            <a href="#">
              <img src={instagram} alt='instagram' className="w-8 h-auto object-cover" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={facebook} alt='facebook' className="w-8 h-auto object-cover" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={youtube} alt='youtube' className="w-8 h-auto object-cover " />
            </a>
          </li>
        </ul>
        <div className="mt-8 mx-auto text-center">
          <button className="underline mx-auto text-center text-base font-bold text-[#4854e2]">
            Privacy Policy
          </button>
        </div>
      </div>
    </Box>
  );

  return (

    <div className="relative">
      <div>
        <div className="flex items-center absolute left-[52px] top-[-3px]">
          <div className="cursor-pointer">
          </div>
          <a className="text-[#f3f3f3] text-2xl font-bold leading-[24px]" href="#">
            PhotoFace
          </a>
        </div>
      </div>

      <div>
        {(['left'] as const).map((anchor) => (
          <Fragment key={anchor}>
            <div onClick={toggleDrawer(anchor, true)}>
              <img src={menu} alt='menu' className="w-6 h-auto mr-3" />
            </div>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
