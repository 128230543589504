import axios, { AxiosRequestConfig } from 'axios'

const API_URL = process.env.REACT_APP_API_KEY

const instance = axios.create({
    baseURL: API_URL,
    headers: {},
});

instance.interceptors.request.use(
    (config: any) => {
        let eventId: any 
        let token:any
        if (sessionStorage.getItem('eventId_fulleventaccess')) {
            eventId = sessionStorage.getItem('eventId_fulleventaccess')
          } else {
            eventId = sessionStorage.getItem('eventId')
          }
          if(sessionStorage.getItem('eventId_fulleventaccess')){
              token = localStorage.getItem(`token_fulleventaccess_${eventId}`)
          }else{
            token = localStorage.getItem(`token_${eventId}`)
          }
        if (token) {
            config.headers["Authorization"] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// http common for api get request
export const get = (url: string, params?: AxiosRequestConfig | undefined) => {
    return instance
        .get(`${API_URL}/${url}`, { params })
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}


// http common for api post request
export const post = (url: string, payload?: any, config = {}) =>
    instance
        .post(`${API_URL}/${url}`, payload, config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })

// http common for api delete request
export const delet = (url: string) =>
    axios
        .delete(`${API_URL}/${url}`)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })

// http common for api multiple delete request
export const deletes = (url: string, payload: AxiosRequestConfig) => {
    axios
        .delete(`${API_URL}/${url}`, { data: payload })

        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

const http = {
    get,
    post,
    delet,
    deletes,
}

export default http
