import { useState, createContext } from "react";

// material-ui
import { AlertColor } from "@mui/material";

// type interface
import { AppContextInterface, Children } from "../interface/ContextInterface";

export const AppContext = createContext<AppContextInterface>({
    name: '',
    setName: () => undefined,
    imageList: [],
    setImageList: () => undefined,
    retriveFaceDetails: {},
    setRetriveFaceDetails: () => undefined,
    isOpen: false,
    setIsOpen: () => undefined,
    alertType: "success",
    setAlertType: () => undefined,
    responseMessage: "",
    setResponseMessage: () => undefined,
    isVerifyPin: false,
    setIsVerifyPin: () => undefined,
    setIsCaptureImage: () => undefined,
    isCaptureImage: false,
    setIsRegister: () => undefined,
    isRegister: false,
    setIsFullEventAccess: () => undefined,
    isFullEventAccess: false
})

const AppContextProvider = ({ children }: Children) => {

    const [name, setName] = useState("ABC")
    const [retriveFaceDetails, setRetriveFaceDetails] = useState<any>()
    const [isOpen, setIsOpen] = useState(false)
    const [alertType, setAlertType] = useState<AlertColor>("success")
    const [responseMessage, setResponseMessage] = useState<string>('')
    let eventId: any
    if (sessionStorage.getItem('eventId_fulleventaccess')) {
        eventId = sessionStorage.getItem('eventId_fulleventaccess')
    } else {
        eventId = sessionStorage.getItem('eventId')
    }
    const [isVerifyPin, setIsVerifyPin] = useState<any>(() => {
        let verifyPin: any
        if (sessionStorage.getItem('eventId_fulleventaccess')) {
            verifyPin = localStorage.getItem(`isVerifyPin_fulleventaccess_${eventId}`)
        } else {
            verifyPin = localStorage.getItem(`isVerifyPin_${eventId}`)
        }
        if (verifyPin) { return true }
        else { return false };

    })

    const [isFullEventAccess, setIsFullEventAccess] = useState<any>(() => {
        let isFullEventAccess: any
        if (sessionStorage.getItem('eventId_fulleventaccess')) {
            isFullEventAccess = localStorage.getItem(`isFullEventAccess_fulleventaccess_${eventId}`)
        } else {
            isFullEventAccess = localStorage.getItem(`isFullEventAccess_${eventId}`)
        }
        if (isFullEventAccess) { return true }
        else { return false };
    })

    const [isRegister, setIsRegister] = useState<any>(() => {
        const register = localStorage.getItem(`isRegister_${eventId}`)
        if (register) { return true }
        else { return false };
    })

    const [isCaptureImage, setIsCaptureImage] = useState<any>(() => {
        let captureImage: any
        if (sessionStorage.getItem('eventId_fulleventaccess')) {
            captureImage = localStorage.getItem(`isCaptureImage_fulleventaccess_${eventId}`)
        } else {
             captureImage = localStorage.getItem(`isCaptureImage_${eventId}`)
        }
        if (captureImage) { return true }
        else { return false };
    })

    const [imageList, setImageList] = useState<any>((() => {
        let imageData: any
        if (sessionStorage.getItem('eventId_fulleventaccess')) {
            imageData = localStorage.getItem(`imageList_fulleventaccess_${eventId}`)
        } else {
            imageData = localStorage.getItem(`imageList_${eventId}`)
        }
        const imagesPathList = JSON.parse(imageData)
        if (imagesPathList) {
            return imagesPathList
        }
    }))


    const value = {
        name,
        setName,
        imageList,
        setImageList,
        retriveFaceDetails,
        setRetriveFaceDetails,
        isOpen,
        setIsOpen,
        alertType,
        setAlertType,
        responseMessage,
        setResponseMessage,
        isVerifyPin,
        setIsVerifyPin,
        setIsCaptureImage,
        isCaptureImage,
        setIsRegister,
        isRegister,
        isFullEventAccess,
        setIsFullEventAccess
    }

    return (
        <>
            <AppContext.Provider value={value}>{children}</AppContext.Provider>
        </>
    )
}

export { AppContextProvider }