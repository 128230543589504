// Routing
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Appcontext
import { useAppContext } from "../hooks/useAppContext";

// Components
import App from "../App";
import Register from "../components/Register";
import CaptureImage from "../components/CaptureImage";
import Home from "../components/Home";
import GetPin from "../components/GetPin";
import ErrorPage from "../components/ErrorPage";
import FullEventAccess from "../components/FullEventAccess";
import UnPublishPage from "../components/UnPublishPage";

// Snackbar
import CustomSnackbar from "../components/CustomSnackbar";

const AppRoutes = () => {
  const {
    isOpen,
    setIsOpen,
    alertType,
    responseMessage,
    isVerifyPin,
    isCaptureImage,
    isRegister,
    isFullEventAccess,
  } = useAppContext();

  let token: any;
  let eventId: any;

  if (sessionStorage.getItem("eventId_fulleventaccess")) {
    eventId = sessionStorage.getItem("eventId_fulleventaccess");
    token = localStorage.getItem(`token_fulleventaccess_${eventId}`);
  } else {
    eventId = sessionStorage.getItem("eventId");
    token = localStorage.getItem(`token_${eventId}`);
  }
  // if (sessionStorage.getItem('eventId_fulleventaccess')) {
  //     token = localStorage.getItem(`token_fulleventaccess_${eventId}`)
  // } else {
  //     token = localStorage.getItem(`token_${eventId}`)
  // }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/unPublish" element={<UnPublishPage />} />
            {!token && (
              <>
                <Route path="/:id/*" element={<Navigate to="/error" />} />
                <Route
                  path="/:id/fulleventaccess/*"
                  element={<Navigate to="/error" />}
                />
                <Route
                  path="/:id/fulleventaccess"
                  element={<Navigate to="getPin" />}
                />
                <Route
                  path="/:id/fulleventaccess/getPin"
                  element={<GetPin />}
                />
              </>
            )}
            {token && isFullEventAccess && (
              <>
                <Route path="/:id/*" element={<Navigate to="/error" />} />
                <Route path="/error" element={<ErrorPage />} />
                <Route
                  path="/:id/fulleventaccess"
                  element={<FullEventAccess />}
                />
              </>
            )}
            {!isVerifyPin && !isRegister && !isCaptureImage && (
              <>
                <Route index element={<Navigate to="/error" />} />
                <Route path="/*" element={<ErrorPage />} />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="/:id" element={<GetPin />} />
              </>
            )}
            {isVerifyPin && !isRegister && !isCaptureImage && (
              <>
                <Route index element={<Navigate to="/register" />} />
                <Route path="/*" element={<Navigate to="/register" />} />
                <Route path="/register" element={<Register />} />
              </>
            )}

            {isVerifyPin && isRegister && !isCaptureImage && (
              <>
                <Route index element={<Navigate to="/captureImage" />} />
                <Route path="/*" element={<Navigate to="/captureImage" />} />
                <Route path="/captureImage" element={<CaptureImage />} />
              </>
            )}

            {isVerifyPin && isRegister && isCaptureImage && (
              <>
                <Route index element={<Navigate to="/home" />} />
                <Route path="/*" element={<Navigate to="/home" />} />
                <Route path="/captureImage" element={<CaptureImage />} />
                <Route path="/home" element={<Home />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
      <CustomSnackbar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        alertType={alertType}
        responseMessage={responseMessage}
      />
    </>
  );
};

export default AppRoutes;
