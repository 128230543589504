/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

// navigation
import { useLocation, useNavigate, useParams } from "react-router-dom";

// ApiService
import ApiService from "../service/ApiDataRequest";

// Sidebar Component
import Sidebar from "../menu/Sidebar";

// moment
import moment from "moment";

// react-pin-input
import { usePinInput } from "react-pin-input-hook";

// App context
import { useAppContext } from "../hooks/useAppContext";

let isFullEventAccess = false;

const Register = () => {
  const { setIsVerifyPin, setIsFullEventAccess } = useAppContext();
  const location = useLocation();

  const API_URL = process.env.REACT_APP_API_KEY;
  const [eventData, setEventData] = useState({
    eventName: "",
    eventDate: "",
    coverImage: "",
  });
  const [values, setValues] = useState(Array(4).fill(""));
  const [coverImage, setCoverImage] = useState("");
  const [isPinValid, setIsPinValid] = useState(false);

  const { fields } = usePinInput({
    values,
    onChange: (values) => {
      setValues(values);
    },
  });
  const navigate = useNavigate();
  const eventId: any = useParams();

  useEffect(() => {
    if (location.pathname.includes("getPin")) {
      localStorage.setItem(
        `isFullEventAccess_fulleventaccess_${eventId.id}`,
        "true"
      );
      setIsFullEventAccess(true);
      isFullEventAccess = true;
    } else {
      isFullEventAccess = false;
    }
  }, []);

  // function for api call to get event details
  const getEventDetail = async () => {
    try {
      const response = await ApiService.getEventById(eventId.id);
      if (response.statusCode === 200) {
        setEventData(response.data);
        setCoverImage(response.data.coverImage.replace(/\\/g, "/"));
        if (location.pathname.includes("getPin")) {
          localStorage.setItem(
            `coverImage_fulleventaccess_${eventId.id}`,
            response.data.coverImage.replace(/\\/g, "/")
          );
        } else {
          localStorage.setItem(
            `coverImage_${eventId.id}`,
            response.data.coverImage.replace(/\\/g, "/")
          );
        }
        // coverImage = response.data.coverImage
      } else {
        navigate("/error");
      }
    } catch (error: any) {
      navigate("/unPublish");
    }
  };

  useEffect(() => {
    // localStorage.setItem(`eventId`, eventId.id)
    if (location.pathname.includes("getPin")) {
      sessionStorage.setItem("eventId_fulleventaccess", eventId.id);
    } else {
      sessionStorage.setItem("eventId", eventId.id);
    }
    getEventDetail();
  }, []);

  // function for api call to verify pin
  const submitPin = async () => {
    let pin = "";
    values.forEach((value) => {
      pin += value;
    });
    const pinValue = +pin;

    try {
      await ApiService.verifyEventPin(eventId.id, pinValue).then((res) => {
        if (res.statusCode === 200) {
          if (location.pathname.includes("getPin")) {
            localStorage.setItem(
              `token_fulleventaccess_${eventId.id}`,
              res.data.token
            );
            localStorage.setItem(
              `isVerifyPin_fulleventaccess_${eventId.id}`,
              "true"
            );
          } else {
            localStorage.setItem(`token_${eventId.id}`, res.data.token);
            localStorage.setItem(`isVerifyPin_${eventId.id}`, "true");
          }
          setIsVerifyPin(true);

          if (isFullEventAccess) {
            navigate(`/${eventId.id}/fulleventaccess`);
          } else {
            navigate("/register");
          }
        } else {
          setIsPinValid(true);
        }
      });
    } catch (error) {
      console.log("error", error);
      setIsPinValid(true);
    }
  };

  return (
    <>
      <div className="relative">
        <div className="grid min-h-screen grid-cols-coverImg bg-theme-light dark:bg-dark-gray relative max-md:grid-cols-1">
          <div
            className="pinbg-image overflow-hidden relative lg:h-screen bg-center bg-no-repeat bg-cover aspect-video p-[3rem] w-full max-md:p-6"
            style={{ backgroundImage: `url('${API_URL}/${coverImage}')` }}
          >
            <div>
              <Sidebar isShow={false} />
            </div>
            <div className="flex justify-center items-start flex-col h-full">
              <h2 className="text-white text-3xl font-semibold uppercase">
                {eventData?.eventName}
              </h2>
              <p className="text-white text-lg font-semibold uppercase">
                {moment(eventData?.eventDate).format("DD-MM-YYYY")}
              </p>
            </div>
          </div>
          <div className="xl:pl-24 xl:pt-[100px] xl:pr-16 pt-[26px] pb-[36px] max-xl:p-6 max-sm:px-4 relative overflow-hidden">
            <div>
              <div className="border-b border-[#f3f3f3] pb-8">
                <h6 className="text-base text-black font-normal leading-[18px]">
                  Get your images using face recognition at
                </h6>
                <h2 className="text-black text-3xl font-bold capitalize mt-2">
                  Event
                </h2>
              </div>
              <div className="pt-8">
                <h6 className="text-base text-black font-normal leading-[18px] capitalize">
                  Enter by telling us the secret PIN
                </h6>
                <div className="mt-8">
                  <div className="mb-2 flex items-center justify-between mx-3">
                    <h6 className="text-sm text-[#666] font-normal">
                      Enter PIN*
                    </h6>
                    {isPinValid && (
                      <h6 className="text-sm text-[#e16a6a] font-normal">
                        Invalid PIN
                      </h6>
                    )}
                  </div>
                  <div className="flex gap-6"></div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      submitPin();
                    }}
                  >
                    <div className="pin-input flex gap-4">
                      {fields.map((propsField: any, index: any) => (
                        <input
                          key={index}
                          className="pin-input__field w-1/4 text-center rounded-md box-inner-shadow h-14 text-[#666] font-medium text-base"
                          {...propsField}
                        />
                      ))}
                    </div>

                    <div className="mx-auto text-center mt-10">
                      <button
                        type="submit"
                        className="next-btn text-base font-semibold w-[10rem] find-btn text-white capitalize tracking-wide py-2 px-4 hover mt-[53px] xl:mb-[4rem] lg:mt-[12.875rem]"
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
