import error from "../assets/image/error.png"

const ErrorPage = () => {
  return (
    <div className="mx-auto text-center">
      <img alt='error' src={error} className="mx-auto text-center"/>
    </div>
  )
}

export default ErrorPage
