import ReactDOM from 'react-dom/client';

// Style
import './index.css';

// App Routes
import AppRoutes from './routing/AppRoutes';

// Context Provider
import { AppContextProvider } from './context/AppContext';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <AppContextProvider>
    <AppRoutes />
  </AppContextProvider>
);
